import React from 'react';

interface Props {
	fill: string
	style?: React.CSSProperties | undefined
}

const ArrowDown: React.FC<Props> = ({ fill, style }) => {
	return (
		<svg
			width="21"
			height="11"
			viewBox="0 0 21 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="arrow-down"
			style={style}
		>
			<path
				d="M0.751647 1.96885C0.2167 1.83155 -0.105661 1.28659 0.0316333 0.751647C0.168928 0.2167 0.713888 -0.105661 1.24884 0.0316334C3.6346
					0.643942 5.55468 1.35494 7.17512 2.78189C8.3459 3.81288 9.30857 5.17117 10.2046 7.01384C11.1004 5.17191 12.0628 3.81406 13.2333
					2.78334C14.8538 1.35639 16.7738 0.645397 19.1596 0.033089C19.6946 -0.104205 20.2395 0.218157 20.3768 0.753104C20.5141 1.28805
					20.1917 1.83301 19.6568 1.97031C17.3591 2.56002 15.8141 3.17565 14.5551 4.28433C13.3624 5.33462 12.3383 6.90651 11.2635 9.56424C11.321
					10.01 11.0707 10.4541 10.6329 10.6239C10.4953 10.6772 10.3532 10.698 10.2149 10.6901C10.0699 10.7016 9.9202 10.6814 9.77554
					10.6253C9.33702 10.4553 9.08659 10.01 9.14522 9.56349C8.07028 6.90534 7.04617 5.33326 5.85336 4.28287C4.59435 3.1742 3.04936 2.55856 0.751647 1.96885Z"
				fill={fill}
			/>
		</svg>
	);
};

export default ArrowDown;
